<template>
  <div class="single_box">
    <!-- 左侧头像 -->
    <div class="left_box">
      <el-tag class="tag" size="mini" type="success" v-if="tag !== null">
        {{ tag.tag }}
      </el-tag>
      <img
        v-if="singleInfo.author !== null && singleInfo.author.avatar !== null"
        :src="singleInfo.media"
        alt=""
      />
      <img v-else src="../../../assets/imgs/mrtx.png" alt="" />
    </div>
    <!-- 右侧部分 -->
    <div class="right_box">
      <div class="top_1">
        <div>
          {{ singleInfo.author !== null ? singleInfo.author.name : '未知' }}
        </div>
        <div v-if="singleInfo.author !== null">
          ID:{{ singleInfo.author.id }}
        </div>
        <div>{{ singleInfo.send_time }}</div>
        <img
          @click="showDia"
          src="@/assets/imgs/mark.png"
          title="打标签"
          alt=""
        />
        <el-button
          @click="handleDel(id)"
          v-if="$route.path === '/tagged/messageTag'"
          type="text"
        >
          取消标记
        </el-button>
      </div>
      <div class="content_box">
        <div>{{ singleInfo.text }}</div>
        <div>
          来自群:{{
            singleInfo.group !== null ? singleInfo.group.name : '未知'
          }}
        </div>
      </div>
      <div class="img_box" v-if="singleInfo.media !== null">
        <img
          @click="handleImg"
          :src="singleInfo.media"
          alt=""
          :style="enlarge ? '' : 'height:300px'"
        />
      </div>
    </div>
    <!-- 标签弹框 -->
    <el-dialog
      title="添加标签"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <LabelForm @reciverLabel="reciverLabel" v-if="dialogVisible" />
    </el-dialog>
  </div>
</template>

<script>
import LabelForm from '@/components/LabelForm.vue'
import { addMessagesLabel } from '@/api/whatsApp'
import { delMessageTag } from '@/api/tagged'

export default {
  name: 'SingleMessage',
  props: {
    singleInfo: Object,
    tag: {
      type: Object,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  components: {
    LabelForm
  },
  data() {
    return {
      dialogVisible: false,
      enlarge: false // 放大缩小图片
    }
  },
  created() {},
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    showDia() {
      this.dialogVisible = true
    },
    async sendResult(val) {
      const data = {
        ids: val.tags
      }
      await addMessagesLabel(this.singleInfo.id, data)
      this.dialogVisible = val.flag
    },
    // 收到选中的标签
    reciverLabel(val) {
      // 收到值发送请求
      if (val.tags.length !== 0) {
        this.sendResult(val)
      } else {
        // 否则关闭弹框
        this.dialogVisible = val.flag
      }
    },
    handleImg() {
      this.enlarge = !this.enlarge
    },
    // 取消标记
    handleDel(id) {
      this.$confirm('此操作将取消该标记, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delMessageTag(id)
          this.$emit('reciveVal', true)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.single_box {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #f2f4f8;
  margin-bottom: 10px;
  padding: 13px 10px;
  overflow: hidden;
  .el-button {
    padding: 0 !important;
    margin-left: 10px;
  }

  .left_box {
    display: flex;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .tag {
      margin: 10px 10px 0 0;
    }
  }
  .right_box {
    width: calc(100% - 50px);
    font-size: 12px;
    color: #666;
    margin-left: 15px;

    .top_1 {
      display: flex;

      div {
        margin-right: 10px;
      }
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .content_box {
      padding-top: 5px;
      display: flex;
      :last-child {
        margin-left: 20px;
        white-space: nowrap;
      }
    }
  }
}
</style>
