const addTabs = function (that, name, id, pathName) {
  that.$store.commit('settings/pushGroupId', {
    name: name,
    id: id,
    pathName: pathName

  })
  // let path = '/group/detail/' + id
  // that.$router.push({
  //   path: path
  // })
}
export { addTabs }
